import React from 'react'
import { Button, Table, Space, Popconfirm } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { ADMIN_PATHS } from 'constants/paths'

const Clients = () => {

  return (
    <div className="page-admin-developers">
      
    </div>
  )
}

export default Clients