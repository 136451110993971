
import React from 'react'

const MyJobs = () => {
  return (
    <div className="page-developer-jobs">
      My Jobs
    </div>
  )
}

export default MyJobs