import React from 'react'

const Client = () => {
  return (
    <div>
      Client
    </div>
  )
}

export default Client