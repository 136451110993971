import React from 'react'
import { Col, Row, Tag, Rate, Typography, Button, Space, Input, Popconfirm } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'

const Developer = () => {
  return (
    <div className="page-admin-developer-profile">

    </div>
  )
}

export default Developer